<template>
<section class="content-wrapper">
    <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo-beekeeper.png">
                            </div>
                            <h4 class="text-center mt-4">Bonjour {{myinfos.firstname}} {{myinfos.lastname}}!</h4>
                            <h6 class="font-weight-light text-center">Entrer votre nouveau mot de passe</h6>
                            <form class="pt-3" @submit.prevent="handleSubmit">
                            <div class="form-group">
                                <input type="text" v-model="password" class="form-control form-control-lg" id="password" placeholder="Entrer votre nouveau mot de passe">
                            </div>
                            <div class="mt-3">
                                <button class="btn btn-block  btn-lg font-weight-medium auth-form-btn" style="background:#0055a4; color:white">Confirmer</button>
                            </div>
                            <div class="text-center mt-4 font-weight-light">
                                Vous n'avez pas reçu le code? <router-link to="/auth-pages/register" class="text-primary">Renvoyer</router-link>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import axios from 'axios' 
export default {
    name:'changePassword',
    data () {
        return {
            password:'',
            myinfos:''
        }
    },
    methods: {
        async handleSubmit () {
            const response = await axios.put(`myinfos/${this.myinfos.ulid}`,
            {
                password: this.password
            },
            {
                withCredentials: true,
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            console.log({response})
            this.$snotify.success('Nouveau mot de passe enregistré', {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            });
            this.$router.push({
                name: 'profil'
            })
            }
            
    },
    mounted () {
        axios.get('myinfos/', {
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token')
            }
        })
        .then ( resMyinfos => {
            this.myinfos= resMyinfos.data.result
        })
        .catch(errMyinfos=>console.log(errMyinfos))
    }
}
</script>